import "../scss/index.scss";
import "focus-visible";
import setupSentry from "./sentry";
import { Application } from "@hotwired/stimulus";
import LottiePlayer from "./LottiePlayer";
import Carousel from "./Carousel";
import ReflectionForm from "./ReflectionForm";

console.log(`gode ${window.bridge.VERSION}`);

setupSentry();

const Stimulus = Application.start();
Stimulus.register("carousel", Carousel);
Stimulus.register("lottiePlayer", LottiePlayer);
Stimulus.register("reflectionForm", ReflectionForm);
